<template>
  <Bandbhand />
</template>

<script>
import Bandbhand from "../../../components/bandb_hand";

export default {
  name: "bandb_wash",
  components: {
    Bandbhand,
  },
};
</script>